<template>
	<modal size="medium" :hide-footer="true" :show-close="false" :isEnableKeyUp="false" @close="close" v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Announcement') }}:
      </h5>
		</div>
		<div slot="body">
      <!-- Loader -->
			<div class="row">
				<div class="col-md-12">
					<div class="mb-12">
            <div class="font-bold text-2xl mb-6">{{ data?.title }}</div>
						{{ data?.content }}
					</div>
          <div slot="footer">
            <div class="modal-action flex justify-end items-center">
              <vs-button type="relief" @click="close">OK</vs-button>
            </div>
          </div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ModalBroadcastMessage',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    otp: {
      handler(newOtp) {
        if (newOtp.join('').length === this.otpDigits) {
          this.onOtpComplete();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 6px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
  border: 1px solid red !important;
  }
}
.code-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
		border: 1px solid red !important;
    }
}
.code-input::-webkit-inner-spin-button,
.code-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
