<template>
	<div class="page-error h-screen flex w-full">
		<div class="vx-col flex justify-center flex-col w-1/2 md:w-1/1 mx-auto text-left align-start error-wrapper">
			<img src="@/assets/images/logo/koblatil.png" alt="graphic-404" class="mb-4 w-1/2 md:w-1/1" />
			<div class="divider-line w-1/2 md:w-full"></div>
			<div class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse text-black w-1/2 md:w-full">
				<h3>
					<div>{{ $t('Sorry, Event not found or URL is changed') }}</div>
					<div>{{ $t('Please contact admin support') }} {{ $t('or') }} <router-link to="/">{{ $t('Go to event list') }}</router-link></div>
				</h3>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'EventNotFound',
  components: {
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {
    console.log(this.$i18n.locale);
    this.$store.dispatch('setLocale', 'en');
  },
  destroyed() {},
};
</script>
