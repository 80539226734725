<template>
	<modal size="medium" :color-mask="'black'" :hide-footer="false" :show-close="false" :isEnableKeyUp="false" @close="close" v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Invite Only Event') }}:</h5>
		</div>
		<div slot="body">
      <!-- Loader -->
      <div class="p-5 text-center" v-if="isChecking">
        <div class="is-loading large dark"></div>
      </div>

			<div class="row" v-if="!isChecking">
				<div class="col-md-12">
					<div class="mb-12">
						{{ $t('Access code required to access this event, please enter your access code below, access code are sent via email.') }}
					</div>

					<div class="mt-10 mb-5">
            <input
              v-for="(digit, index) in otpDigits"
              :key="index"
              v-model="otp[index]"
              ref="otpInput"
              @input="handleInput(index, $event)"
              @keydown="handleKeyDown(index, $event)"
              @paste="handlePaste($event)"
              class="otp-input"
              maxlength="1"
              type="text"
            />
					</div>

					<!-- <div class="text-blue mt-4" v-if="isOnlyParticipantEvent">
						{{ $t('If you dont have a access code') }} <span @click="openRegister()" class="font-bold pointer">{{ $t('please register here') }}</span>
					</div> -->
				</div>
			</div>
		</div>
		<div slot="footer">
			<div class="modal-action flex justify-between items-center" v-if="!isChecking">
        <span class="cursor-pointer text-blue" @click="backToHome()">
          {{ $t("Return to Koblatil") }}
        </span>
				<vs-button type="relief" :class="{ 'is-loadiwng': isSubmitting }" @click="submit()">{{ $t('Submit') }}</vs-button>
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import participantApi from '@/api/participant';
import eventApi from '@/api/event';
import { getAxiosErrorMessage } from '@/lib/helper';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ModalAccessCodeRequired',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isOnlyParticipantEvent: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
    participant: {
      type: Object,
      default: null,
    },
    eventAccessCode: {
      type: String,
      default: null,
    },
  },
  watch: {
    otp: {
      handler(newOtp) {
        if (newOtp.join('').length === this.otpDigits) {
          this.onOtpComplete();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      isChecking: false,
      otpDigits: 6,
      otp: Array(6).fill(''), // Initialize an array with 6 empty strings
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  mounted() {
    this.checkCurrentKey();
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    handleInput(index, event) {
      // Convert input to uppercase
      this.otp[index] = event.target.value.toUpperCase();

      // Move focus to the next input field on input
      if (event.target.value && index < this.otpDigits - 1) {
        this.$refs.otpInput[index + 1].focus();
      }
    },
    handleKeyDown(index, event) {
      const key = event.key;

      // Allow only alphanumeric characters and Backspace
      if (!/^[a-zA-Z0-9]$/.test(key) && key !== 'Backspace') {
        event.preventDefault();
      }

      // Move focus to the previous input field on Backspace when the current field is empty
      if (key === 'Backspace' && index > 0 && !this.otp[index]) {
        this.$refs.otpInput[index - 1].focus();
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      console.log(pastedData);

      // Ensure pastedData is a 6-digit string
      if (pastedData.length === 6) {
        console.log('test');
        this.otp = pastedData.split('');
      }
    },
    onOtpComplete() {
      this.accessCode = this.otp.join('');
      this.submit();
    },
    close() {
      this.$emit('close');
    },
    checkCurrentKey() {
      const currentKey = localStorage.getItem(this.event.slug);
      if (currentKey) {
        if (this.isOnlyParticipantEvent) {
          this.accessCode = currentKey;
          this.isChecking = true;

          setTimeout(() => {
            this.verifyParticipantCode();
          }, 1000);
        }
      }
    },
    openRegister() {
      const routeData = this.$router.resolve({ name: 'UserEventDetails', params: { slug: this.event.slug } });
      window.open(`${routeData.href}`, '_blank');
    },
    handleOnComplete(value) {
      this.accessCode = value;
      this.submit();
    },
    handleOnChange(value) {
      this.accessCode = value;
    },
    handleClearInput() {
      this.$refs.codeInput.clearInput();
    },
    submit() {
      if (this.isOnlyParticipantEvent) {
        this.verifyParticipantCode();
      } else {
        this.verifyEventCode();
      }
    },
    storeKey() {
      if (this.accessCode) {
        const accessCode = this.accessCode.toUpperCase();
        localStorage.setItem(this.event.slug, accessCode);
      }
    },
    verifyEventCode() {
      this.isSubmitting = true;
      setTimeout(() => {
        const eventCode = this.eventAccessCode ? this.eventAccessCode.trim() : this.eventAccessCode;
        const inputCode = this.accessCode ? this.accessCode.trim() : this.accessCode;
        const codeA = eventCode ? eventCode.toUpperCase() : eventCode;
        const codeB = inputCode ? inputCode.toUpperCase() : inputCode;
        if (codeA === codeB) {
          this.close();
          this.storeKey();
          this.isSubmitting = false;
          this.accessCode = null;
          this.otp = Array(6).fill('');
        } else {
          this.isSubmitting = false;
          this.$vs.notify({
            title: this.$t('Access Code'),
            text: this.$t('Wrong access code, please check your accees code'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.accessCode = null;
          this.otp = Array(6).fill('');
        }
      }, 500);
    },
    verifyParticipantCode() {
      localStorage.removeItem('access_token');

      const inputCode = this.accessCode ? this.accessCode.trim() : this.accessCode;

      this.isSubmitting = true;
      this.isChecking = true;
      const callback = (response) => {
        // Set token
        const tokenData = response.data.token;
        if (tokenData) {
          this.$store.dispatch('loginOnTheFly', tokenData);
        }

        this.close();
        this.storeKey();
        this.isSubmitting = false;
        this.isChecking = false;
        this.accessCode = null;
        this.otp = Array(6).fill('');
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Access Code'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSubmitting = false;
        this.isChecking = false;
        this.accessCode = null;
        this.otp = Array(6).fill('');
      };
      eventApi.getByCode(inputCode, callback, errorCallback);
    },
    createParticipant() {
      if (!this.user || this.participant) return;
      const params = {
        event_id: this.event.id,
        email: this.user.email,
        is_check_in: 1,
      };
      const callback = (response) => {
        const participant = response.data;
        this.setParticipant(participant);
      };
      const errorCallback = () => {
      };
      participantApi.create(params, callback, errorCallback);
    },
    setParticipant(participant) {
      this.$emit('setParticipant', participant);
    },
    backToHome() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 6px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
  border: 1px solid red !important;
  }
}
.code-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
		border: 1px solid red !important;
    }
}
.code-input::-webkit-inner-spin-button,
.code-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
